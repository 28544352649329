<template>
  <LayoutDefault>
    <div class="bg-tertiary">
      <v-container class="pa-sm-0 pt-sm-5 position-relative">
        <v-row>
          <v-col class="pl-1">
            <div>
              <nuxt-link
                to="/"
                class="text-decoration-none"
              >
                <v-icon>mdi-chevron-left</v-icon>
                <!-- eslint-disable vue/html-closing-bracket-newline -->
                <span class="text-decoration-underline"
                  >Retour à l'accueil</span
                >
                <!-- eslint-enable vue/html-closing-bracket-newline -->
              </nuxt-link>
            </div>
          </v-col>
        </v-row>
        <v-row class="pb-sm-0 mx-0 mb-0 border-bottom-white">
          <v-col class="pl-0 text-start">
            <AppTitle
              text="Comment Participer ?"
              :width="328"
              :height="42"
              :y="28"
              :font-size="32"
              :stroke-width="5"
              :shadow-offset="2"
              class="hidden-xs"
            />
            <AppTitle
              text="Comment Participer ?"
              :width="290"
              :height="34"
              :y="22"
              :font-size="28"
              :stroke-width="4"
              :shadow-offset="2"
              class="hidden-sm-and-up"
            />
          </v-col>
          <v-col class="hidden-xs pr-0 text-end">
            Besoin d'aide ?<br />
            Rendez-vous sur notre
            <nuxt-link :to="{ path: localePath('faq'), hash: $route.hash }">
              FAQ
            </nuxt-link>
          </v-col>
        </v-row>
      </v-container>
      <div
        class="pt-16 pt-sm-4 d-flex"
        :class="{
          'overflow-y-hidden': !mobile,
          'justify-center': !mobile,
        }"
      >
        <div
          class="position-relative pt-12 pt-sm-0"
          :class="{
            'flex-grow-1': mobile,
          }"
        >
          <AppProfileTabs class="position-relative z-index-1">
            <AppProfileTab
              title="Le Jeu"
              :to="{
                path: localePath('how-to-participate'),
                hash: t(URL_HASH_FOR_GAME),
              }"
              :width="mobile ? '50%' : '300px'"
              :hide-icon="mobile"
            />
            <AppProfileTab
              title="La Grande Boutique"
              :to="{
                path: localePath('how-to-participate'),
                hash: t(URL_HASH_FOR_SHOP),
              }"
              :width="mobile ? '50%' : '300px'"
              :hide-icon="mobile"
            />
          </AppProfileTabs>

          <img
            src="~/assets/images/profile/layout/pot-holder-blue.png"
            class="hidden-xs img--pot-holder-blue"
          />
          <img
            src="~/assets/images/profile/layout/pot-holder-red.png"
            class="hidden-xs img--pot-holder-red"
          />
          <img
            v-show="$route.hash === t(URL_HASH_FOR_GAME)"
            src="~/assets/images/faq/game/pot-holder-blue-mobile.png"
            class="hidden-sm-and-up z-index-0 img--pot-holder-blue-game-mobile"
          />
          <img
            v-show="$route.hash === t(URL_HASH_FOR_GAME)"
            src="~/assets/images/faq/game/pot-holder-red-mobile.png"
            class="hidden-sm-and-up z-index-0 img--pot-holder-red-game-mobile"
          />
          <img
            v-show="$route.hash === t(URL_HASH_FOR_SHOP)"
            src="~/assets/images/faq/shop/pot-holder-blue-mobile.png"
            class="hidden-sm-and-up z-index-0 img--pot-holder-blue-shop-mobile"
          />
          <img
            v-show="$route.hash === t(URL_HASH_FOR_SHOP)"
            src="~/assets/images/faq/shop/pot-holder-red-mobile.png"
            class="hidden-sm-and-up z-index-0 img--pot-holder-red-shop-mobile"
          />
        </div>
      </div>
    </div>
    <div class="position-relative bg-primary overflow-hidden">
      <img
        src="/images/confettis/profile-main-layout.png"
        class="z-index-0 hidden-xs img--background"
      />
      <div class="position-relative z-index-1">
        <slot />
      </div>
    </div>
  </LayoutDefault>
</template>

<script setup lang="ts">
import { URL_HASH_FOR_GAME, URL_HASH_FOR_SHOP } from '@/utils/constants'

import LayoutDefault from './default.vue'

const { mobile } = useDisplay()
const localePath = useLocalePath()
const { t } = useI18n()
</script>

<style lang="scss" scoped>
.img--background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.img--pot-holder-blue {
  position: absolute;
  bottom: -290px;
  left: -380px;
}
.img--pot-holder-red {
  position: absolute;
  bottom: -390px;
  right: -230px;
}
.img--pot-holder-blue-game-mobile {
  position: absolute;
  bottom: -120px;
  left: 0px;
}
.img--pot-holder-red-game-mobile {
  position: absolute;
  bottom: -200px;
  right: 10px;
}
.img--pot-holder-blue-shop-mobile {
  position: absolute;
  bottom: -130px;
  left: 0px;
}
.img--pot-holder-red-shop-mobile {
  position: absolute;
  bottom: -140px;
  right: 10px;
}

.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
</style>
